.items {
  margin-bottom: 1rem;
}

.input {
  height: 50px;
  width: calc(100% - 10px);
  border-radius: 20px;
  padding: 15px;
}

.question_links {
  text-decoration: none;
  transition: text-decoration-color 0.5s ease-in-out;
}

.question_links:hover {
  text-decoration: underline;
  text-decoration-color: rgb(8, 180, 172);
}

.space {
  padding: 20px;
}
.related_navbar {
  display: block;
  text-decoration: none;
  text-align: right;
}
.related_navbar:hover {
  text-decoration: underline;
  text-decoration-color: rgb(8, 180, 172);
}
.list {
  display: block;
  padding: 3px;
  text-decoration: none;
  color: inherit;
}

.lists {
  text-align: right;
}


.fixwidth {
  position: relative;
}

.input-container {
  padding: 30px;
  padding-top: 0;
  text-align: center;
}

.question-list {
  margin-left: 25px;
}

#curves div {
  width: 20px;
  height: 15px;
  border-left: 3px solid #999;
}

#curves.width div {
  border-color: transparent transparent transparent #999;
}

#curve1 {
  -moz-border-radius: 50px 0 0 50px;
  border-radius: 50px 0 0 50px;
  padding: 10px;
}

@media (max-height: 732px) {
  #left {
    top: 85px;
    height: 88vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@media (min-width: 1200px) {
  #container {
    display: flex;
    flex-direction: row;
    margin: auto;
    max-width: 1400px;
    padding-left: 20px;
    padding-right: 10px;
  }

  #left {
    flex-basis: 400px;
    position: -webkit-sticky;
    position: sticky;
    top: 15vh;
    align-self: flex-start;
    padding-right: 20px;
    padding-bottom: 20px;
    text-align: left;
  }

  #middle {
    flex-basis: 1400px;
    margin: 0 auto;
    border-left: 1px solid whitesmoke;
    border-right: 1px solid whitesmoke;
    padding: 30px;
  }

  #right {
    flex-basis: 450px;
    height: auto;
    text-align: right;
    padding: 20px;
    padding-right: 0px;
  }



  .input-container {
    padding: 30px;
    padding-top: 0;
    text-align: center;
  }

  .question-list {
    margin-left: 25px;
  }

  #curves div {
    width: 20px;
    height: 15px;
    border-left: 3px solid #999;
  }

  #curves.width div {
    border-color: transparent transparent transparent #999;
  }

  #curve1 {
    -moz-border-radius: 50px 0 0 50px;
    border-radius: 50px 0 0 50px;

  }
}
@media (min-width: 767px) and (max-width: 1199px) {
  #container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: auto;
    padding: 25px;

  }

  #left {
    width: 300px;
    position: -webkit-fixed;
    position: fixed;
    top: 16vh;
    align-self: start;
    /* margin-left: -320px; */
    padding-bottom: 20px;
    text-align: left;
  }

  #leftt {
    display: show;
  }

  #middle {
    padding-top: 20px;
    margin: 0 auto;

    margin-left: 300px;
    border-left: 1px solid whitesmoke;
    padding-left: 20px;
  }

  #right {
    display: block;
    width: 100%;
    padding-left: 23px;
    padding-top: 70px;
    margin-left: 300px;
  }

  .input-container {
    padding: 8px;
  }

  .question-list {
    margin-left: 2px;
  }

  .question-list>li {
    padding: 7px;
  }
}
@media (max-width: 766px) {
  #container {
    display: block;
    flex-wrap: wrap;
    position: relative;
    margin: auto;
    width: 90%;
    padding-left: 15px;
    padding-right: 10px;
  }

  #left {
    display: none;
  }

  #middle {
    padding: 10px;
    margin-top: 5px;
  }

  #right {
    padding-left: 18px;
  }
}