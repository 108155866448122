.html_learning {
  width: 641px;
  height: 358px;
}
/*  html related links */
.html_links {
  padding: 0%;
  padding-right: 15px;
}

.topic {
  text-align: center;
}

.blockShadow {
  list-style: none;
  border-bottom: 1px solid #3399ff;
  padding: 20px;
  padding-left: 0;
}

.HtmlLinks {
  text-decoration: none;
  color: inherit;
}

.ContentTitle{
  padding: 30px;
  padding-left: 0;
}
.ContentList{
  padding: 5px;
  padding-left: 0;
}

.html_attributes_table {
  border: 1px solid #000;
  text-align: center;
  margin-top: 40px;
  width: 100%;
}
.html_attributes_table > thead > tr > th {
  background-color: rgb(10, 166, 187);
  border: 1px solid #000;
  color: #fff;
  padding: 20px;
}
.html_attributes_table > tbody {
  border: 1px solid #000;
}
.html_attributes_table > thead > tr {
  border: 1px solid #000;
  padding: 20px;
}
.html_attributes_table > tbody > tr > td {
  border: 1px solid #000;
  text-align: left;
  padding: 30px;
}

#own_width {
  overflow: auto;
  /* new */
  flex-grow: 1;
}

/* table styling */
.table_image {
  width: 540px;
  height: 379px;
}
@media (max-width: 766px) {
  .related_links {
    margin-left: -9px;
  }
  .html_links {
    padding: 0%;
    margin-top: 50px;
  }
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 0 0 1rem 0;
  }

  tr:nth-child(odd) {
    background: #ccc;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 2px;
    left: 10px;
    width: 100%;
    white-space: nowrap;
  }

  /*
  		Label the data
      You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
  		*/
  td:before {
    content: attr(data-label);
    color: rgb(10, 166, 187);
  }
}
