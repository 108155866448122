.Respawn {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: var(--text-center);
  width: 100%;
  position: absolute;
  bottom: 0;
}

.glassesBackground {
  width: 100px;
  height: 100px;
  background-color: #ffffff10;
  box-shadow: inset 20px 20px 60px #ffffff10, inset -20px -20px 60px #ffffff10;
  border-radius: 50px;
}

.Respawn div:nth-child(1) {
  animation: spin 5s linear forwards infinite alternate;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  overflow-y: hidden;
}
.Respawn div:nth-child(2) {
  animation: spin 6s linear forwards infinite alternate;
  width: 80px;
  height: 80px;
  border-radius: 80px;
}
.Respawn div:nth-child(3) {
  animation: spin 7s linear forwards infinite alternate;
  width: 90px;
  height: 90px;
  border-radius: 50px;
}
.Respawn div:nth-child(4) {
  animation: spin 4s linear forwards infinite alternate;
  width: 110px;
  height: 110px;
  border-radius: 50px;
}
.Respawn div:nth-child(5) {
  animation: spin 10s linear forwards infinite alternate;
  width: 65px;
  height: 65px;
  border-radius: 50px;
}

.contin {
  height: 50vh;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: var(--text-center);
}
.contin > .weTheOne {
  flex: 50%;
  height: 48vh;
}
.contin > .Connecting {
  flex: 50%;
  height: 48vh;
}

.contin > div > img {
  padding-top: 20px;
  padding-left: 20px;
  height: 100%;
  width: calc(100% - 20px);
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background: #e0e0e0;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
  object-fit: cover;
}

.B_g_WhatWeDo {
  background-color: var(--Learn-Background-Color);
  color: var(--default-text-color);
  padding-top: 22px;
  padding-bottom: 25px;
}
.whatWeDo {
  margin: auto;
  width: 90%;
  padding: 30px;
  resize: both;
  overflow: auto;
  position: relative;
}

.WhatWeDoInfoHeader {
  font-weight: bold;
  text-align: center;
  padding: 20px;
}
.WhatWeDoInfo {
  text-align: left;
}

.B_g_WhoWeAre {
  background-color: rgb(6, 6, 6);
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 30px), 0 100%);
  color: var(--default-text-color);
}

.Container_WhoWeAre {
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 90%;
  gap: 2rem;
  padding-top: 20px;
  padding: 22px;
}
.Whoweare {
  width: 55%;
}
.System-gif {
  width: 35%;
}
.WhoweareInfoHeader {
  padding-bottom: 22px;
}
.WhoweareInfo {
  padding-top: 20px;
}
.System {
  margin-top: 70px;
  width: 100%;
  height: 340px;
  opacity: 0.2;
}
.WhoweareHighlight {
  font-weight: bold;
  font-size: 1.7rem;
  color: #6de6e6;
  text-align: center;
}


@media (max-width: 640px) {
  .Container_WhoWeAre {
    flex-direction: column;
  }
  .Whoweare {
    width: 100%;
  }

  .System-gif {
    width: 100%;
  }
}

@media (min-width: 641px) and (max-width: 970px) {
  .Container_WhoWeAre {
    flex-direction: column;
  }
  .Whoweare {
    width: 100%;
  }

  .System-gif {
    width: 100%;
  }
}

@keyframes spin {
  0% {
    transform: translateY(2000px);
  }

  40% {
    background-color: aqua;
  }

  70% {
    background-color: rgb(187, 20, 20);
  }

  100% {
    transform: translateY(-1000px);
  }
}

.Info {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  word-wrap: break-word;
  text-align: left;
  padding: 10px;
}
.HeadingTeam {
  margin-bottom: 180px;
  padding: 30px;
  font-weight: bold;
  text-align: center;
}

.containert {
  padding: 2px 16px;
  margin-top: 30px;
}

@media (min-width: 767px) {
  .ToWhom {
    height: auto;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 90%;
    text-align: var(--text-center);
    margin-top: 100px;
    overflow-anchor: none;
    position: relative;
  }

  .ToWhom > .weTheOne {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    line-height: 48vh;
    text-align: var(--text-center);
    border-bottom-right-radius: 590px 100px;
    border-top-right-radius: 590px 100px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    background-image: linear-gradient(
      rgb(185, 163, 163) 5%,
      rgb(62, 80, 62) 59%,
      rgb(127, 127, 204) 155%
    );
    text-align: left;
    padding: 10px;
  }

  #Left_Filp {
    animation: Left 5s ease-in;
  }

  @keyframes Left {
    0% {
      transform: scaleX(-1) translateX(-100%);
    }
    100% {
      left: 0%;
      transform: rotateY(0deg);
    }
  }

  .ToWhom > .Connecting {
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2;
    background-image: linear-gradient(
      rgb(185, 163, 163) 10%,
      rgb(62, 80, 62) 56%,
      rgb(151, 151, 240) 170%
    );
    border-bottom-right-radius: 590px 100px;
    border-top-right-radius: 590px 100px;
    border-bottom-left-radius: 590px 100px;
    border-top-left-radius: 590px 100px;
    line-height: 48vh;
    text-align: var(--text-center);
    text-align: var(--text-left);
    padding: 10px;
  }

  .testss {
    height: 100%;
    width: calc(100% - 20px);
    border-radius: 3% 90%;
    object-fit: cover;
  }

  .ToWhom > .Third {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    line-height: 55vh;
    text-align: var(--text-center);
    border-bottom-left-radius: 590px 100px;
    border-top-left-radius: 590px 100px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    background-image: linear-gradient(
      rgb(185, 163, 163) 5%,
      rgb(62, 80, 62) 59%,
      rgb(133, 133, 204) 150%
    );
    text-align: var(--text-left);
    padding: 10px;
  }

  #Right_Filp {
    animation: Right 5s ease-in;
  }

  @keyframes Right {
    0% {
      transform: scaleX(-1) translateX(100%);
    }

    100% {
      left: 0%;
      transform: rotateY(0deg);
    }
  }



.Team {
  margin: auto;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
  grid-gap: 150px 20px;
  padding-bottom: 80px;
}

.card {
  background: #e0e0e0;
  box-shadow: inset 20px 20px 60px #a1c9c9, inset -20px -20px 60px #7ba3a3;
  transition: 0.3s;
  color: black;
  padding: 20px;
}

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }

  .card>.PersonImage {
    position: absolute;
    object-fit: fill;
    left: calc(50% - 100px);
    top: -20%;
    height: 200px;
    width: 200px;
    border-radius: 100px;
    margin-top: -100px;
    border: 5px solid var(--background-color);
  }
  #ComeFromLeft {
    animation: left 1s ease-in;
  }

  @keyframes left {
    from {
      left: -100px;
    }

    to {
      left: 0px;
    }
  }

  #ComeFromRight {
    animation: right 1s ease-in;
  }

  @keyframes right {
    from {
      right: -100px;
    }

    to {
      right: 0px;
    }
  }

  #SmallToBig {
    animation: smalls 1s ease-in;
  }

  @keyframes smalls {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
}

.HeadingCoreValue {
  text-align: center;
  margin-top: 70px;
  padding-bottom: 50px;
}
.CoreValue {
  margin: auto;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(400px, 100%), 1fr));
  grid-gap: 50px 20px;
}
.Value_Title_Color {
  padding-bottom: 20px;
  font-weight: bold;
  color: black;
}
@media (max-width: 767px) {
  .ToWhom {
    height: auto;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    margin: auto;
    width: 95%;
    text-align: var(--text-center);
    margin-top: 30px;
  }

  .ToWhom > .weTheOne {
    text-align: var(--text-center);
    background-image: linear-gradient(
      rgb(185, 163, 163) 10%,
      rgb(62, 80, 62) 56%,
      rgb(151, 151, 240) 170%
    );
    border-radius: 8px;
  }

  .ToWhom > .Connecting {
    background-image: linear-gradient(
      rgb(185, 163, 163) 10%,
      rgb(62, 80, 62) 56%,
      rgb(151, 151, 240) 170%
    );
    word-wrap: break-word;
    border-radius: 8px;
  }

  .testss {
    height: 100%;
    width: calc(100% - 20px);
    border-radius: 3% 90%;
    object-fit: cover;
  }

  .ToWhom > .Third {
    text-align: var(--text-center);
    background-image: linear-gradient(
      rgb(185, 163, 163) 5%,
      rgb(62, 80, 62) 59%,
      rgb(133, 133, 204) 150%
    );
    word-wrap: break-word;
    border-radius: 8px;
  }
  @keyframes spin {
    0% {
      transform: translateY(4400px);
    }

    40% {
      background-color: aqua;
    }

    70% {
      background-color: rgb(187, 20, 20);
    }

    100% {
      transform: translateY(-1050px);
    }
  }
}
