.container-large {
  height: auto;
  margin: auto;
  width: 90%;
  margin-top: 3px;
  padding: 10px;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
}

.flex-child {
  width: 307px;
  text-align: center;
  padding: 10px;
  margin: 10px;
  border: 5px solid #dd8d24;
  background-color: var(--bg-programming);
  -webkit-transition-duration: 0.5s;
  /* Safari */
  transition-duration: 0.5s;
  color: #dd8d24;
}

.flex-child:hover {
  transform: rotate(0deg);
  border: none;
}

.container-L-image {
  background-color: var(--Paragraph-Background-Color);
}
.non-related {
  padding: 20px;
  padding-top: 40px;
  text-align: center;
  color: var(--default-text-color);
  font-weight: bold;
}
.Static-container {
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: auto;
  width: 90%;
  display: grid;
  grid-template-rows: minmax(30px, auto);
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: space-between;
  gap: 50px;
}
.image > img {
  height: 400px;
  width: 100%;
  border-radius: 30% 70% 50% 30% / 30% 30% 70% 50%;
  object-fit: cover;
}
.context {
  margin: auto;
  width: 50%;
  padding: 60px;
  font-size: var(--Header-font-size);
}

.b_g_Info {
  background-color: var(--Everything-Background-Color);
  color: var(--default-text-color);
  padding-bottom: 20px;
}

.Benefit_bg {
  background-color: var(--Bg-Benefit-Color);
  color: var(--default-text-color);
  padding-bottom: 20px;
}

.Mission_bg {
  background-color: var(--Mission-Background-Color);
  color: var(--default-text-color);
  padding-bottom: 70px;
}

.Learn_bg {
  background-color: var(--Learn-Background-Color);
  color: var(--default-text-color);
  padding-bottom: 70px;
}

/* .Team_bg{
    background-color: var(--default-text-color);
    color: black;
} */
.Team_Info {
  margin: auto;
  width: 90%;
  height: auto;
  margin-bottom: calc(150px);
  padding: 22px;
}

.HeadingTitle {
  margin: 30px;
}

.Link_Color {
  color: var(--Links--Color);
  text-decoration: none;
  font-weight: bold;
}

.Link_Color:hover {
  color: rgb(138, 180, 248);
  text-decoration: underline;
}

.bg_programming {
  background-color: var(--bg-programming);
}

.programming_name {
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: auto;
  width: 90%;
  display: grid;
  grid-template-rows: minmax(30px, auto);
  grid-template-columns: repeat(auto-fit, minmax(385px, 1fr));
  justify-content: space-between;
  gap: 50px;
}

.programming_name > div {
  border: 1px solid var(--bg-programming);
  text-align: center;
  position: relative;
}

.programming_name > div > h1 {
  padding: 10px;
  padding-top: 15px;
}

.programming_name > div > p {
  padding-bottom: 50px;
  padding-left: 10px;
  padding-right: 10px;
}

.different_site {
  text-decoration: none;
  background-color: rgb(143, 96, 2);
  border-radius: 10px;
  color: var(--default-text-color);
  padding: 10px;
  position: absolute;
  bottom: -10px;
  margin: -402px -193px 0px -70px;
  border-top: 4px solid var(--bg-programming);
  border-left: 4px solid var(--bg-programming);
  border-right: 4px solid var(--bg-programming);
  font-weight: bold;
}

.html {
  background-color: var(--background-color-1);
  border-radius: 5px;
}

.css {
  background-color: var(--background-color-2);
  border-radius: 5px;
}

.javascript {
  background-color: var(--background-color-3);
  border-radius: 5px;
}

.python {
  background-color: var(--background-color-4);
  border-radius: 5px;
}

.reactjs {
  /* background-color: var(--background-color-4); */
  background-color: var(--background-color-1);
  border-radius: 5px;
}

.nodejs {
  /* background-color: rgb(235, 188, 235); */
  background-color: var(--background-color-2);
  border-radius: 5px;
}

.csharp {
  background-color: var(--background-color-5);
  border-radius: 5px;
}

.java {
  /* background-color: var(--background-color-3); */
  background-color: var(--background-color-4);
  border-radius: 5px;
}

.cplusplus {
  /* background-color: rgb(155, 154, 224); */
  background-color: var(--background-color-6);
  border-radius: 5px;
}

.sql {
  background-color: var(--background-color-7);
  border-radius: 5px;
}

.php {
  background-color: var(--background-color-5);
  border-radius: 5px;
}

.ruby {
  /* background-color: rgb(155, 154, 224); */
  background-color: var(--background-color-4);
  border-radius: 5px;
}

.git {
  background-color: var(--background-color-3);
  border-radius: 5px;
}

.windows {
  background-color: var(--background-color-7);
  border-radius: 5px;
}

.linux {
  background-color: var(--background-color-5);
  border-radius: 5px;
}

@media (max-width: 1545px) {
  .Static-container {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
}
@media (min-width: 762px) and (max-width: 955px) {
  .programming_name {
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

@media (max-width: 762px) {
  .flex-container {
    display: block;
    padding-left: 0;
  }

  .flex-child {
    width: 100%;
  }

  .Static-container {
    grid-template-columns: 1fr;
  }

  .programming_name {
    grid-template-columns: 1fr;
  }

  .programming_name > div > h1 {
    padding: 10px;
    padding-top: 15px;
  }
}
