* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  background-color: var(--background-color);
  color: var(--Black-text-color); /* default text color for all the pages*/
  font-size: var(--default-font-size);
  line-height: 35px;
  letter-spacing: 0.02em;
}

.change-theme {
  background-color: #36383f;
  color: whitesmoke;
}
@media (max-width: 640px) {
  .App {
    font-size: var(--default-font-size-mobile);
  }
}

@media (min-width: 641px) and (max-width: 970px) {
  .App {
    font-size: var(--default-font-size-tablet);
  }
}

.loading {
  margin: auto;
  border: 20px solid var(--Black-text-color);
  border-radius: 50%;
  border-top: 20px solid var(--question-link-color);
  width: 200px;
  height: 200px;
  animation: spinner 1.5s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Team styling */
.HeadingTitle {
  font-weight: bold;
  padding: 20px;
  text-align: center;
}

.NoUnderLine {
  text-decoration: none;
  color: var(--question-link-color);
}

.pyScriptFullPath {
  color: var(--question-link-color);
}
.CodeSampleBackground {
  background-color: var(--SampleCode-background);
  padding: 20px;
  /* color: whitesmoke; */
}
.testcolor {
  color: var(--question-link-color);
}
.pl-kos {
  color: var(--Bracket-Color);
}
.pl-ent {
  color: var(--HTML-tag-Color);
}
.pl-c1 {
  color: var(--HTMl-attribute-Color);
}
.pl-s {
  color: var(--HTMl-attribute-Name-Color);
}
.copy {
  float: right;
}
.OneLine {
  color: var(--One--line-color);
  list-style: none;
}
.Note {
  font-weight: bold;
}
.react_keyword {
  color: var(--React-Keyword-Color);
}
.React_Funtion {
  color: var(--React-Function-Color);
}
.html_tag {
  color: var(--html-tag-color);
}
.react_imports {
  color: var(--react-import-color);
}
.onedrive_restore {
  width: 100%;
  height: 500px;
  border: none;
}
.onedrive_icon {
  width: 100%;
}

.btn {
  position: fixed;
  right: 0;
  padding: 0.4rem;
  width: 4rem;
  background: rgb(0, 0, 0);
  color: gold;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  height: 90vh;
}

.btn:hover {
  color: white;
  background: blue;
}
.first {
  background-color: #ffffff;
  width: 50%;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre;
  box-shadow: 0 1px 1px rgb(22, 22, 22);
  outline: none;
  padding: 0.4rem;
  height: 90vh;
}

.second {
  background-color: rgb(255, 255, 255);
  width: 50%;
  overflow-y: auto;
  white-space: pre;
  right: 0;
  box-shadow: 0 1px 1px rgb(22, 22, 22);
  padding: 0.4rem;
  height: 90vh;
}

.pane {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}
.EditorMode {
  width: 100%;
  background-color: rgb(247, 252, 252);
  word-wrap: break-word;
  --color-canvas-subtle: #f6f8fa;
}
.EditorOutput {
  width: 100%;
  background-color: rgb(245, 245, 248);
  height: 100%;
}
.text-editor-output {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.active {
  border-bottom: 3px solid red;
}

.code_highlight {
  color: var(--React-Keyword-Color);
  padding: 20px;
  /* color: whitesmoke; */
}
