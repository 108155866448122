
.Title{
    text-align: center;
    padding: 20px;
    padding-bottom: 30px;
}
.contact_info{
    margin: 0 auto;
    width: 90%;
    display: grid;
    text-align: center;
}
.wrapper{
    margin: 0 auto;
    width: 90%;
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    grid-template-areas: "map email_form";
    background-color: white;
    gap: 20px;
    margin-top: 70px;
}
.submit_message{
    text-align: center;
}
.map{
    grid-area: map;
}
.email_form{
    grid-area: email_form;
}
.map-iframe{
    width: 100% !important;
    height: 100% !important;
    padding-bottom: 20px;
}
.email_form{
    display: grid;
    grid-template-areas: 
    "fullname email"
     "subject subject"
     "message message";
    padding: 20px;
    gap: 30px;

}
.fullname{
    grid-area: fullname;
}
.email{
    grid-area: email;
}
.subject{
    grid-area: subject;
}
.message{
    grid-area: message;
}
.submit{
    width: 50%;
    height: 50px;
    margin-left: 0;
    border-radius: 10px;
    -webkit-transition: all .5s ease-in;
    transition: all .5s ease-in-out;
}
.submit:hover {
    border: 1px solid black;
}
.input_fullname{
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    -webkit-transition: all .5s ease-in;
    transition: all .5s ease-in-out;
}
.input_email{
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    -webkit-transition: all .5s ease-in;
    transition: all .5s ease-in-out;
}
.input_subject{
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    -webkit-transition: all .5s ease-in;
    transition: all .5s ease-in-out;
}
.input_message {
    width: 100%;
    border-top: none;
    border-left: none;
    border-right: none;
    -webkit-transition: all .5s ease-in;
    transition: all .5s ease-in-out;
}
.input_fullname:focus{
    outline: none;
    border-bottom: 1px solid black;
    
}
.input_email:focus {
    outline: none;
    border-bottom: 1px solid black;
}
.input_subject:focus {
    outline: none;
    border-bottom: 1px solid black;
}
.input_message:focus {
    outline: none;
    border-bottom: 1px solid black;
}

@media (max-width: 640px){
    .contact_info{
        grid-template-columns: repeat(1 1fr);
    }
    .wrapper {
        margin: 0 auto;
        width: 90%;
        display: block;
        background-color: white;
        margin-top: 70px;
    }
    .map {
        width: 100%;
        height: 400px;
        display: block;
        margin-top: 20px;
    }
    .email_form {
        width: 100%;
        grid-template-areas:
        "fullname"
        "email"
        "subject"
        "message";
        padding: 20px;
        margin-top: 50px;

    }

}
@media (min-width: 641px) and (max-width: 970px) {
    .contact_info {
        grid-template-columns: repeat(2, 1fr);
    }
    .wrapper {
        margin: 0 auto;
        width: 90%;
        display: block;
        background-color: white;
        margin-top: 70px;
    }

    .map {
        width: 100%;
        height: 400px;
        display: block;
        margin-top: 20px;
    }

    .email_form {
        width: 100%;
        grid-template-areas:
                "fullname email"
                "subject subject"
                "message message";
        padding: 20px;
        margin-top: 50px;

    }
}
@media (min-width:971px){
    .contact_info {
        grid-template-columns: repeat(4, 1fr);
    }
}