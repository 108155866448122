/* moblie */
@media (max-width: 480px) {
  .each-slide > .section {
    position: relative;
    height: 315px;
    background-color: var(--Mini-info-bg-color);
  }

  .test {
    background-repeat: no-repeat;
    width: 100%;
    height: 240px;
  }

  .text-block {
    position: absolute;

    bottom: -15px;
    right: 100px;
  }

  .small {
    font-size: 18px;
  }
}

/* tablet Ipads */
@media (min-width: 481px) and (max-width: 767px) {
  .each-slide > div {
    position: relative;
    height: 350px;
    background-color: var(--Mini-info-bg-color);
  }

  .test {
    background-repeat: no-repeat;
    width: 100%;
    height: 270px;
  }

  .text-block {
    position: absolute;
    top: 83%;
    bottom: 0px;
    right: 140px;
    color: var(--default-text-color);
  }

  .small {
    font-size: 18px;
  }
}

/* tablet Ipads portrait mode */
@media (min-width: 767px) and (max-width: 1024px) {
  .each-slide > div {
    position: relative;
    height: 590px;
    background-color: var(--Mini-info-bg-color);
  }

  .test {
    background-repeat: no-repeat;
    width: 100%;
    height: 490px;
  }

  .text-block {
    position: absolute;
    top: 87%;
    bottom: 0px;
    right: 300px;
    color: white;
  }
  .small {
    font-size: 18px;
  }
}

/* laptops and desktops  */
@media (min-width: 1025px) and (max-width: 1080px) {
  .each-slide > div {
    position: relative;
    height: 600px;
    border: 1px solid;
    background-color: var(--Mini-info-bg-color);
  }

  .test {
    background-repeat: no-repeat;
    width: 100%;
    height: 500px;
  }

  .text-block {
    position: absolute;
    top: 87%;
    bottom: 0px;
    right: 400px;
    color: white;
  }

  .small {
    font-size: 18px;
  }
}

/* large screen*/
@media (min-width: 1081px) {
  .each-slide > div {
    position: relative;
    height: 600px;
    border: 1px solid;
    background-color: var(--Mini-info-bg-color);
  }

  .test {
    background-repeat: no-repeat;
    width: 100%;
    height: 620px;
  }

  .text-block {
    position: absolute;
    top: 80%;
    bottom: 10px;
    right: calc(30% - 80px);
    color: black;
  }

  .small {
    font-size: 18px;
  }
}
