.servey-title{
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
    padding: 25px;
}
/* 1160 */
.servey-request{
    margin:auto;
    width: 90%;
    padding: 14px;
    text-align: center;
}
.survey-form{
    margin: auto;
    width: 90%;
    margin-top: 20px;

    position: relative;
    display: grid;

    /* grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    grid-template-rows: minmax(30px, auto); */
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    grid-gap: 40px;
    /* line-height: 33px;
    letter-spacing: 0.131em;
    font-size: 20px; */
    padding-bottom: 25px;

}


.survey-form-device-group{
    grid-column: span 1;
    background-color: var(--background-color-4);
    padding: 20px;
    border-radius: 5px;
}
.survey-form-browser-group{
    grid-column: 3;
    background-color: var(--background-color-4);
    padding: 20px;
    border-radius: 5px;
}
.survey-form-interest-group{
    background-color: var(--background-color-7);
    padding: 20px;
    border-radius: 5px;
}
.survey-form-rating-group{
    grid-column: 2;
    grid-row: 1/ span 2;
    background-color: var(--background-color-6);
  
    padding: 20px;
    line-height: 40px;
    border-radius: 5px;
}
.survey-form-submit-button{
    background-color: var(--background-color-7);
    display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid green;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 5px;
    
}
.submit-survey{
    
    padding: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    background-color: var(--bg-programming);
    color: var(--default-text-color);
    width: 35%;
}
.survey-interest-selection{
    width: 40%;
    text-align: center;
    border-radius: 5px;
}
.survey-interest-option{
    padding: 30px;
    text-align: center;
}

@media (min-width: 874px) and (max-width: 1545px) {
    .survey-form{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 873px) {
    .survey-form{
        grid-template-columns: repeat(2, 1fr);
        gap : 20px;
    }
    .survey-form-device-group {
        background-color: var(--background-color-4);
        padding: 20px;
        border-radius: 5px;
    }

    .survey-form-browser-group {

        background-color: var(--background-color-7);
        padding: 20px;
        border-radius: 5px;
        grid-column: 1;
    }

    .survey-form-interest-group {
        background-color: var(--background-color-7);
        padding: 20px;
        border-radius: 5px;
    }

    .survey-form-rating-group {
        background-color: var(--background-color-6);
        grid-column: 2;
        padding: 20px;
        line-height: 40px;
        border-radius: 5px;
    }

}

@media (max-width: 430px){
 
  
    .survey-form-device-group {
      grid-row: 1;
      grid-column: 1;

    }

    .survey-form-browser-group {
      grid-row: 1;
      grid-column: 2 /-1;

    }

    .survey-form-rating-group {
  
       grid-row: 2;
       grid-column: 1/ -1;
       line-height: 25px;

    }
    .survey-interest-selection{
        width: 100px;
    }
    .submit-survey{
        width: 100%;
    }

}
@media (max-width: 329px) {


    .survey-form-device-group {
        grid-row: 1;
        grid-column: 1 /-1;

    }

    .survey-form-browser-group {
        grid-row: 3;
        grid-column: 1/-1;

    }

    .survey-form-rating-group {

        grid-row: 2;
        grid-column: 1/ -1;
        line-height: 25px;

    }
    .survey-form-interest-group {
        grid-row: 4;
        grid-column: 1/-1;
    }

    .survey-form-submit-button {
        grid-row: 5;
        grid-column: 1/-1;
    }
    .submit-survey {
        width: 50%;
    }

}