@media (min-width: 1405px) {
  .size {
    position: -webkit-sticky;
    position: sticky;
    padding: relative;
    top: 0;
    z-index: 5;
    /* giving higher number to make sure it stays on top */

    background-color: var(--background-color);

  }

  #leftt,
  #ShowInVerySmall {
    display: none;
  }

  #bg {
    opacity: 0.9;
    font-weight: bold;
    min-height: 10vh;
    margin: auto;
    width: 1400px;
    padding: 25px;
  }
  #bgg {
    opacity: 0.9;
    font-weight: bold;
    min-height: 10vh;
    margin: auto;
    width: 90%;
    padding: 25px;
    padding-left: 22px;
    padding-right: 20px;
  }
}

.change-color {
  color: var(--default-text-color);
}

.login {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  opacity: 0.9;
  font-weight: bold;
  color: inherit;
}
@media ((min-width: 767px) and (max-width:1404px)) {

  .size {
    position: -webkit-sticky;
    position: sticky;
    padding: relative;
    top: 0;
    z-index: 5;
    /* giving higher number to make sure it stays on top */

    background-color: var(--background-color);


  }

  #leftt,
  #ShowInVerySmall {
    display: none;
  }

  #bg {
    opacity: 0.9;
    font-weight: bold;
    min-height: 10vh;

    width: 100vw;
    padding: 25px;
  }

  #bgg {
    opacity: 0.9;
    font-weight: bold;
    min-height: 10vh;
    margin: auto;
    width: 90%;
    padding: 25px;
    padding-left: 22px;
    padding-right: 20px;
  }
}

@media (min-width: 472px) and (max-width: 766px) {
  .size {
    position: -webkit-sticky;
    position: sticky;
    padding: relative;
    top: 0;
    z-index: 5;
    /* giving higher number to make sure it stays on top */

    background-color: var(--background-color);
    display: flex;
    gap: 2px;
  }

  #ShowInVerySmall {
    display: none;
  }

  #leftt {
    opacity: 0.9;
    font-weight: bold;
    min-height: 10vh;
    padding-left: 60px;
  }

  #bg {
    opacity: 0.9;
    font-weight: bold;
    min-height: 10vh;
    margin: auto;
    width: 90%;
    padding: 25px;
    margin-right: calc(7% - 10px);
  }

  #bgg {
    opacity: 0.9;
    font-weight: bold;
    min-height: 10vh;
    margin: auto;
    width: 90%;
    padding: 25px;
    padding-left: 22px;
    padding-right: 20px;
  }
}

@media (max-width: 472px) {
  .size {
    position: -webkit-sticky;
    position: sticky;
    padding: relative;
    top: 0;
    z-index: 5;
    /* giving higher number to make sure it stays on top */

    background-color: var(--background-color);
    display: flex;
    gap: 20px;
  }

  #ShowInVerySmall {
    display: none;
  }

  #leftt {
    opacity: 0.9;
    font-weight: bold;
    min-height: 10vh;
    padding-left: 10px;
    margin-left: 35px;
  }

  #bg {
    opacity: 0.9;
    font-weight: bold;
    min-height: 10vh;
    margin: auto;
    width: 90%;
    padding: 25px;
    margin-right: 30px;
  }

  #bgg {
    opacity: 0.9;
    font-weight: bold;
    min-height: 10vh;
    margin: auto;
    width: 90%;
    padding: 25px;
    padding-left: 22px;
    padding-right: 20px;
  }
}
@media (max-width: 301px) {

  /* 1144 second media query */
  .size {
    position: -webkit-sticky;
    position: sticky;
    padding: relative;
    top: 0;
    z-index: 5;
    /* giving higher number to make sure it stays on top */

    background-color: var(--background-color);
  }

  #HideInVerySmall {
    display: none;
  }

  #ShowInVerySmall {
    display: show;
  }

  #leftt {
    opacity: 0.9;
    font-weight: bold;
    padding: 25px;
  }

  #bg {
    opacity: 0.9;
    font-weight: bold;
    padding: 25px;
  }
}